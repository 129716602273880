<style lang="sass" scoped>
.container-login
  font-size: 12px
.required
  color: tomato
</style>
<template lang="pug">
div.container-login
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.text-center
          strong  Noitaler
        h6.text-muted.text-center 새로운 계정 만들기
        .mb-4
        form.form(@submit.prevent='')
          .pt-4
          .form-group
            label.d-block: strong 이메일 주소
              strong.required.ml-1 *
            input.form-control(type='email' required)
            span.form-text.text-danger 이미 가입된 이메일 주소 입니다.
          .form-group
            label.d-block: strong 비밀번호
              strong.required.ml-1 *
            input.form-control(type='password' required)
            span.form-text.text-muted 비밀번호는 8자리 이상으로 입력해주세요.
          .form-group
            label.d-block: strong 비밀번호 확인
              strong.required.ml-1 *
            input.form-control(type='password' required)
            span.form-text.text-danger 비밀번호를 확인해주세요.
          .form-group
            label.d-block: strong 이름
              strong.required.ml-1 *
            input.form-control(type='text' required)

          .pt-4
          .form-group
            label.d-block: strong 비즈니스 이름 (사업자명)
              strong.required.ml-1 *
            input.form-control(type='text' required)
          .form-group
            label.d-block: strong 웹사이트 주소 URL
            input.form-control(type='text')
          .form-group
            label.d-block: strong 연락처
            input.form-control(type='text')
            b-form-checkbox(v-model='agree2' value='Y' unchecked-value='N') 이용안내 및 고객지원 전화수신 동의
          .form-group
            label.d-block: strong 가입경로
            textarea.form-control(style='font-size: 12px;' placeholder='예) 추천인 아이디, 쿠폰코드, 블로그, 카페')
          .form-group
            label.d-block: strong 문의 남기기
            textarea.form-control(style='font-size: 12px;' rows=5 placeholder='궁금하신 점을 남겨주세요. 초기 데이터 이전이 필요하거나 카카오알림톡 발송은 서비스내 고객센터를 통해 안내드립니다.')

          .pt-4
          .form-group
            label.d-block: strong 약관
            //- pre.bg-white.border.p-4(style='height: 5rem; font-size: 12px; overflow: scroll').
            //-   내용
            //-   내용
            //-   내용
            //-   내용
            //-   내용

            b-form-checkbox(v-model='agree1' value='Y' unchecked-value='N') 서비스이용약관에 동의합니다.
              a.float-right.ml-1(href='#' target='_blank') 서비스이용약관
            b-form-checkbox(v-model='agree3' value='Y' unchecked-value='N') 개인정보보호약관에 동의합니다.
              a.float-right.ml-1(href='#' target='_blank') 개인정보보호약관


        .mb-3
          button.btn.btn-primary.py-4.btn-block(type='submit') 회원가입

</template>

<script>


export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    // this.$store.dispatch('session')
    this.load()
  },
  data() {
    return {
      done: false,
      form: {},
      agree1: 'N',
      agree2: 'Y',
    }
  },
  methods: {
    async load() {
      try {
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
